<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Ücretsiz Kargo Kampanyası</label>
            <b-form-checkbox :checked="form.ucretsiz_kargo_durum" v-model="form.ucretsiz_kargo_durum" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Adetli alımlarda tek desi hesapla</label>
            <b-form-checkbox
              :checked="form.toplu_alim_desi_hesaplama"
              v-model="form.toplu_alim_desi_hesaplama"
              switch
              size="lg"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" v-show="diller.length != 1">
          <b-form-group label="Dil">
            <v-select
              v-model="defaultDil"
              :options="diller"
              :reduce="(diller) => diller.lang"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Fiyat">
            <b-input-group>
              <b-form-input
                size="lg"
                class="rounded-0 text-right"
                v-model="form.fiyat"
                aria-describedby="input-1-live-feedback"
                :disabled="!form.ucretsiz_kargo_durum"
              />
              <b-input-group-prepend is-text> ₺ </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Aciklama">
            <b-input-group>
              <b-form-input
                size="lg"
                class="rounded-0"
                v-model="form.icerik[defaultDil].aciklama"
                aria-describedby="input-1-live-feedback"
                :disabled="!form.ucretsiz_kargo_durum"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: {
    vSelect,
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.form = ref({
      k_no: null,
      icerik: {},
      ucretsiz_kargo_durum: false,
      fiyat: 0,
      toplu_alim_desi_hesaplama: false,
    });

    const createIcerikDilUret = () => {
      langs.forEach((dil) => {
        expo.form.value.icerik[dil.lang] = {
          aciklama: '',
        };
      });
    };

    createIcerikDilUret();

    const handlerGetAllData = async () => {
      context.emit('show', true);
      await store.dispatch('kargoKampanyaListele');
      if (store.getters.getKargoKampanya != null) {
        expo.form.value = store.getters.getKargoKampanya;
      }
      context.emit('show', false);
    };

    handlerGetAllData();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('kargoKampanyaOlustur', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
