<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="Kargo Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Kargo Adı">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Kargo Ödeme Tipi">
            <v-select
              v-model="form.kargo_turu"
              :options="kargoTurleri"
              :reduce="(kargo) => kargo.id"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="Sabit Ücreti (TRY)" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Sabit Ücreti (TRY)">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.sabit_fiyat"
                :disabled="form.kargo_turu == 'desi'"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Kapıda Ödeme Ekstra Ücret (TRY)" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Kapıda Ödeme Ekstra Ücret (TRY)">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.kapida_odeme_fiyat"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row v-if="form.kargo_turu == 'desi'">
        <b-col cols="12" md="6" v-for="(item, index) in form.desi" :key="index">
          <b-input-group>
            <b-input-group-append is-text> {{ item.title }}</b-input-group-append>
            <b-form-input
              size="lg"
              type="number"
              class="rounded-0 text-right"
              v-model="form.desi[index].fiyat"
              aria-describedby="input-1-live-feedback"
              :disabled="form.kargo_turu != 'desi'"
            />
            <b-input-group-prepend is-text> TRY </b-input-group-prepend>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    const { updateData } = toRefs(props);

    expo.kargoTurleri = ref([
      {
        id: 'sabit',
        title: 'Sabit Ücret',
      },
      {
        id: 'desi',
        title: 'Desi Göre Ücret',
      },
    ]);

    expo.form = ref({
      k_no: null,
      baslik: 'Yeni Kargo Tanım',
      kargo_turu: 'sabit',
      sabit_fiyat: 0,
      kapida_odeme_fiyat: 0,
      desi: [],
      statu: false,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('kargoTanimlaGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
            context.emit('updateRefresh', res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
