<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :newSetting="true"
    :totalActive="true"
    :show="show"
    :title="setting ? 'Kargo Kampanya İşlemleri' : 'Kargo Tanimla İşlemleri'"
    :total="rows.length"
    :type="type"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
    @handlerClose="type = $event"
    @handlerSetting="handlerSetting($event)"
  >
    <template v-slot:leftBody>
      <b-alert variant="warning" show class="text-center m-3" v-if="!rows.length">
        <h4 class="alert-heading">Bilgi!</h4>
        <p>Henüz Kayıt Bulunmamaktadır.</p>
      </b-alert>

      <draggable v-else v-model="rows" tag="ul" class="list-group" handle=".draggable-task-handle" @end="handlerMarkaSiraChange">
        <KargoTanimlaItem v-for="(item, index) in rows" :key="index" :item="item" @handlerUpdate="handlerUpdate($event)" />
      </draggable>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type && !setting">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <KargoTanimlaEkle
          v-else-if="type"
          :updateData="updateData"
          @show="show = $event"
          @updateRefresh="handlerUpdatedData"
          @handlerClose="type = $event"
        />

        <KargoKampanyaAyar @show="show = $event" v-if="setting" />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { ref, watch, defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
import KargoTanimlaEkle from './component/KargoTanimlaEkle.vue';
import KargoTanimlaItem from './component/KargoTanimlaItem.vue';
import KargoKampanyaAyar from './component/kargoKampanyaAyar.vue';
import { useToast } from 'vue-toastification/composition';
import draggable from 'vuedraggable';
export default defineComponent({
  components: {
    draggable,
    BListCard,
    KargoTanimlaEkle,
    KargoTanimlaItem,
    KargoKampanyaAyar,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.search = ref(null);
    expo.type = ref(false);
    expo.setting = ref(false);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.rows = ref([]);
    expo.form = ref({
      k_no: null,
      baslik: 'Yeni Kargo Tanım',
      kargo_turu: 'sabit',
      sabit_fiyat: 0,
      kapida_odeme_fiyat: 0,
      desi: [],
      statu: false,
    });

    expo.desiler = ref([
      {
        name: '1_5',
        title: '1 - 5 Desi',
        fiyat: 0,
      },
      {
        name: '6_10',
        title: '6 - 10 Desi',
        fiyat: 0,
      },
      {
        name: '11_15',
        title: '11 - 15 Desi',
        fiyat: 0,
      },
      {
        name: '16_20',
        title: '16 - 20 Desi',
        fiyat: 0,
      },
      {
        name: '21_25',
        title: '21 - 25 Desi',
        fiyat: 0,
      },
      {
        name: '26_30',
        title: '26 - 30 Desi',
        fiyat: 0,
      },
      {
        name: '31_40',
        title: '31 - 40 Desi',
        fiyat: 0,
      },
      {
        name: '41_60',
        title: '41 - 60 Desi',
        fiyat: 0,
      },
      {
        name: '61_80',
        title: '61 - 80 Desi',
        fiyat: 0,
      },
      {
        name: '81',
        title: '81 Desi Üzeri',
        fiyat: 0,
      },
    ]);

    expo.form = ref({
      k_no: null,
      baslik: 'Yeni Kargo Tanım',
      kargo_turu: 'sabit',
      sabit_fiyat: 0,
      kapida_odeme_fiyat: 0,
      desi: [],
      statu: false,
    });

    const handlerDesiOlustur = () => {
      expo.desiler.value.forEach((el) => {
        expo.form.value.desi.push(el);
      });
    };

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('kargoTanimlaListele');
      expo.rows.value = store.getters.getKargoTanimlari;
      handlerDesiOlustur();
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerNewItem = () => {
      expo.show.value = true;
      store
        .dispatch('kargoTanimlaEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Ekleme Başarılı.', { position: 'bottom-left' });
            expo.updateData.value = res.data.data;
            expo.type.value = true;
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            expo.show.value = false;
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.setting.value = false;
      expo.show.value = false;
    };

    expo.handlerUpdatedData = () => {
      expo.rows.value = store.getters.getKargoTanimlari;
    };

    expo.handlerMarkaSiraChange = () => {
      store
        .dispatch('kargoTanimlaSiraGuncelle', { variantgrup: expo.rows.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    expo.handlerSetting = (event) => {
      expo.setting.value = !expo.setting.value;
      expo.type.value = false;
    };

    watch(expo.search, (val) => {
      if (val.length > 0) {
        const search = val.toLocaleLowerCase('TR').trim();
        let filteredData = store.getters.getKargoTanimlari;
        filteredData = filteredData.filter((rows) => {
          return rows.icerik['tr'].baslik.toLocaleLowerCase('TR').includes(search);
        });
        expo.rows.value = filteredData;
      } else {
        expo.rows.value = store.getters.getKargoTanimlari;
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
